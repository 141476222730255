import React, { useEffect, type FC, type PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../store';
import { login } from '../store/userSlice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserState } from '../types';

const userNeedsOnboarding = (
  destinationUrlPath: string,
  user: UserState
): boolean => {
  // return true;
  if (!user) throw new Error('Unexpected empty user');
  if (destinationUrlPath.includes('/onboarding')) {
    // Prevent infinite redirection.
    return false;
  }
  if (user.role !== 'onboarding') {
    // 'admin' or 'publisher' should not be taken to onboarding.
    return false;
  }
  return true;
};

const AuthToAPI: FC<PropsWithChildren> = () => {
  const currentDestination = useLocation();
  const user = useSelector((state: RootState) => state.authenticatedUser);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (user === null) {
      dispatch(login());
    }
  }, [dispatch, user]);

  if (user === null) {
    return <progress></progress>;
  }

  if (userNeedsOnboarding(currentDestination.pathname, user)) {
    return <Navigate to="/onboarding/step1" />;
  }

  // Prevent onboarded users from re-accessing onboarding pages.
  // Unless it's a chicory user performing testing.
  if (
    currentDestination.pathname.includes('onboarding') &&
    user.role !== 'onboarding' &&
    !user.email.includes('@chicory.co')
  ) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AuthToAPI;
